import { useEffect } from "react";

export default function ActvModal(props: any) {

    function OZProgressCommand_OZViewer(step: any, state: any, reportname: any) {
        console.log("[OZProgressCommand] \n- 보고서 생성 단계 : " + step);
        console.log("- 보고서 생성 상태 : " + state);
        console.log("- 보고서 이름 : " + reportname);
        if (step == 4 && state == 2) {
            alert(reportname + " 보고서 실행이 완료되었습니다.");
        }
    }

    // 인쇄
    function OZPrintCommand_OZViewer(msg: any, code: any, reportname: any, printername: any, printcopy: any, printpages: any,
        printrange: any, username: any, printerdrivername: any, printpagesrange: any) {
        console.log("[OZPrintCommand] \n- 프린트 결과 메시지 : " + msg);
        console.log("- 보고서 이름 : " + reportname);
        console.log("- 프린터 이름 : " + printername);
        console.log("- 인쇄 매수 : " + printcopy);
        console.log("- 인쇄된 용지 매수 : " + printpages);
        console.log("- 인쇄 범위 : " + printrange);
        console.log("- 사용자 이름 : " + username);
        console.log("- 프린터 드라이버 이름 : " + printerdrivername);
        console.log("- 인쇄된 페이지 범위 : " + printpagesrange);
        if (code == 0) {
            alert(reportname + " 보고서 인쇄를 했습니다.");
        } else {
            alert(reportname + " 보고서 인쇄를 실패(취소)했습니다.");
        }
    }
    // 저장
    function OZExportCommand_OZViewer(code: any, path: any, filename: any, pagecount: any, filepaths: any) {
        console.log("[OZExportCommand]\n- 파일 경로 : " + path);
        console.log("- 파일 이름 : " + filename);
        console.log("- 페이지 수 : " + pagecount);
        if (code == "1") {
            var arr = filepaths.split('|');
            for (let i = 0; i < arr.length; i++) {
                console.log("- 저장된 파일의 경로와 이름 - " + (i + 1) + "번째 파일 : " + arr[i]);
            }
            alert(path + filename + "에 보고서 저장을 했습니다.");
        } else {
            alert(path + filename + "저장을 실패(취소)했습니다.");
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          function SetOZParamters_OZViewer() {
            let oz;
            oz = document.getElementById("OZViewer");
            oz.sendToActionScript("connection.servlet", "${process.env.REACT_APP_URL_REPORT}/server");
            oz.sendToActionScript("connection.reportname", "/cp_actv.ozr");
            oz.sendToActionScript("connection.pcount", "1");
            oz.sendToActionScript("connection.args1", 'data=` + JSON.stringify(props.data) + `');

    
            oz.sendToActionScript("export.path", "C:\");
            oz.sendToActionScript("export.filename", "test");
            oz.sendToActionScript("export.format", "pdf");
            oz.sendToActionScript("export.confirmsave", "false");
        
            oz.sendToActionScript("print.copies", "1"); 
            oz.sendToActionScript("print.once", "false"); 
            oz.sendToActionScript("print.printername", "DEFAULT_PRINTER"); 
            oz.sendToActionScript("print.size", "A4");
      
            oz.sendToActionScript("viewer.progresscommand", "true"); 
            oz.sendToActionScript("viewer.printcommand", "true"); 
            oz.sendToActionScript("viewer.exportcommand", "true");

            return true;
          }
          start_ozjs("OZViewer", "${process.env.REACT_APP_URL_REPORT}/ozhviewer/");
        `;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (

        <div style={{ margin: "20px" }}>
            <div className="inp-flex">
                <div id="OZViewer" className="OZViewer" style={{ overflowY: "auto", height: "650px" }}></div>
            </div>
        </div>

    )
}